<template>
  <hero />
  <detail/>
  <reviews />
</template>

<script>
import hero from "./hero";
import detail from "./detail";
import reviews from "./reviews";

export default {
  components: {
    hero,
    detail,
    reviews,
  },
};
</script>

