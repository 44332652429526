<template>
  <div class="mt-10">
    <div class="reviews container">
      <h1>Publised Reviews</h1>
      <p>
        As part of the review process, we award medals to each establishment
        that we review; this provides a quick visual indication of the
        performance of an establishment.
      </p>
      <div class="grid">
        <div class="grid-item" v-for="(i, index) in services" :key="index">
          <div class="content">
              <div>
              <img src="https://res.cloudinary.com/hayche-network/image/upload/v1646928346/website/images/reviews/steps_s6keup.svg" alt="" />
              <span>{{ index + 1 }}</span>
              </div>
              <p>{{ i.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          message: `Try detailing your favorite (or least favorite) dishes at your local restaurant.`,
        },
        {
          message: `Talk about an experience that will keep you coming back (or staying away) for years to come.`,
        },
        {
          message: `Highlight an employee who went the extra mile to make your experience memorable.`,
        },
        {
          message: `Describe why you’ll be using this service again for your next project.`,
        },
        {
          message: `Include tips about the best time to arrive, where to park, or where is the best seating.`,
        },
      ],
    };
  },
};
</script>

<style scoped>
/* network services */
.reviews {
  margin-bottom: 155px;
}

.reviews > h1 {
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.reviews > p {
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  width: 495px;
  margin: 0 auto;
  margin-bottom: 67px;
}

@media (max-width: 768px) {
  .reviews p {
    width: 100%;
  }
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 30px;
  row-gap: 50px;
  place-items: center;
}

.grid .grid-item {
  width: 300px;
  height: 100px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
  margin: 0 auto;
}

@media (max-width: 768px) {
  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 50px;
    row-gap: 25px;
    place-items: center;
  }

  .grid .grid-item {
    width: 100%;
    position: relative;
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 10px rgba(193, 193, 193, 0.15);
  }
}

.grid .grid-item .content {
  height: 99px;
  margin-right: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 25% 75%;
}

.grid .grid-item .content img {
  width: 51px;
  height: 51px;
  position: absolute;
  top: 25px;
  left: 15px;
}

.grid .grid-item .content span {
  position: absolute;
  z-index: 999;
  top: 42px;
  left: 37px;
  color: rgba(0, 0, 0, 0.473);
}

.grid .grid-item .content p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  align-items: center;
  color: #1b1b1b;
}

/* end of network services */
</style>